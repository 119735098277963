import Dexie from 'dexie';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { ToolsService } from './../../services/tools.service';
import { UGAU_USER_ROLE } from '../../enum/ugau-user-role';
import { map, debounceTime, concatMap } from 'rxjs/operators';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { RxCollection, RxDatabase, RxJsonSchema, WithDeleted } from 'rxdb';
import {
  MaybePromise,
  ReplicationPullHandlerResult,
  RxConflictHandler,
  RxError,
  RxReplicationWriteToMasterRow,
  RxTypeError,
} from 'rxdb/dist/types/types';
import { WAIT_DURATION } from './../../enum/WAIT_DURATION';
import { GraphQlService } from './../../services/graph-ql.service';
import {
  RxReplicationState,
  replicateRxCollection,
} from 'rxdb/plugins/replication';
import { dexieDeleteIndexedDbs } from '../database-dexie-helpers';
import { sleep } from '../../../common-projects/sleep';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { reloadApp } from './../../services/reloadApp.util';
import { SchemaLiteral } from './SchemaLiteral';
import { Checkpoint } from './Checkpoint.type';
import { BatchData } from './BatchData';
import { auth } from '../../services/nhost';
import { SentryService } from '../../services/sentry.service';

/**
 * Permet d'abstraire la logique RXDB pour la réplication des données avec HASURA
 */
@Injectable({
  providedIn: 'root',
})
export abstract class AbstractProvider<T> {
  public collection!: RxCollection<T>;
  public replicateState!: RxReplicationState<T, Checkpoint> | null;

  protected BATCH_SIZE = 500;
  protected destroyRef = inject(DestroyRef);
  protected idUser!: string;
  protected isSuperAdmin: boolean = false;
  protected migrationState: any;
  protected migrationStrategies = {};
  protected replicateStateSubscriptions: Subscription[] = [];
  protected resetSyncRunning = false;
  protected static DELETED_FLAG = 'deleted_bool';
  protected staticsCollectionFunctions = {};
  protected sub!: Subscription;
  protected token!: string;

  //BehaviorSubject (Observable) pour tous les emplacements (JSON not RXDB) non supprimés
  protected getAllSubject$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public getAll$: Observable<T[]> = this.getAllSubject$.asObservable();
  public getAllByBatch$ = this.getAll$.pipe(
    concatMap((items) => this.createBatchGenerator(items))
  );

  *createBatchGenerator(items: T[]): IterableIterator<BatchData<T>> {
    for (let i = 0; i < items.length; i += this.BATCH_SIZE) {
      const batchItems = items.slice(i, i + this.BATCH_SIZE);
      // Type : start, batch, complete
      const type =
        i === 0
          ? 'start'
          : i + this.BATCH_SIZE >= items.length
            ? 'complete'
            : 'batch';
      yield {
        data: batchItems,
        type,
      };
    }
  }

  protected replicateStateInitialReplicationCompleteListeners: Function[] = [];
  // FIXME : Faut typer le schema, mais il aime pas trop :)
  protected abstract schemaLiteral: SchemaLiteral;
  protected abstract schema: RxJsonSchema<T>;
  public enablePush: boolean = true;

  /**
   *  Permet d'avoir un observable qui emet un event void après une pullRequest du replicateState
   *  - Fonctionnement :
   *  On ecoute "received" du replicateState (un event par élément reçu de la pull query)
   *  On débounce 1 seconde pour être sur que tous les éléments de la pull query sont passés
   *  On émet void sur pullQueryUpdated$ pour prévenir que la pullQuery est terminée
   * @type {Subject}
   */
  pullQueryUpdated$: Subject<any> = new Subject<any>();
  private dbInstance!: RxDatabase;
  protected conflictHandler: RxConflictHandler<T> | undefined;

  protected AT = inject(ToolsService);
  protected auth = inject(AuthenticationService);
  protected gql = inject(GraphQlService);

  public setDbInstance(database: RxDatabase) {
    this.dbInstance = database;
  }

  /**
   * Permet de gérer la création/recréation de la collection RXDB
   * - Si la collection existe, alors elle est retournée
   * - Si la collection n'existe pas, alors elle est créée
   *
   * @async
   * @return {Promise<void>} Aucun retour
   */
  async resetCollection(): Promise<void> {
    const rxCollection = await this.setupCollection();
    if (!rxCollection) return;

    this.collection = rxCollection;
    this.collection
      .find()
      .$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        return this.getAllSubject$.next(
          val.map((el) => el.toJSON(true)) as T[]
        );
      });
  }

  /**
   * Permet la création de la collection
   *
   * @async
   * @return {Promise<RxCollection<T>} La collection
   */
  async setupCollection(): Promise<RxCollection<T> | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.dbInstance[this.schemaLiteral.name]) {
      return this.dbInstance[this.schemaLiteral.name] as RxCollection<T>;
    }
    try {
      const collection = await this.dbInstance.addCollections({
        [this.schemaLiteral.name]: {
          schema: this.schema,
          autoMigrate: this.schemaLiteral.autoMigrate,
          statics: this.staticsCollectionFunctions,
          migrationStrategies: this.migrationStrategies,
          ...(this.conflictHandler && {
            conflictHandler: this.conflictHandler,
          }),
        },
      });

      this.migrationState =
        collection[this.schemaLiteral.name].getMigrationState();
      const needed =
        await collection[this.schemaLiteral.name].migrationNeeded();
      console.log(
        this.schemaLiteral.name + ' : migrationNeeded : ' + needed.toString()
      );

      return collection[this.schemaLiteral.name] as RxCollection<T>;
    } catch (e: any) {
      await this.manageCollectionsError(e);
    }
  }

  private async manageCollectionsError(e: any) {
    console.log(this.schemaLiteral.name + ' : manageCollectionsError');
    console.log(e);
    await dexieDeleteIndexedDbs();
    await sleep(2000);
    return reloadApp(
      'Provider::manageCollectionsError ' + this.schemaLiteral.name
    );
  }

  getById$(id: string | number) {
    return this.collection
      .findOne()
      .where('id')
      .equals(id)
      .$.pipe(map((el) => el?.toJSON()));
  }

  /**
   * Permet la mise en place de la synchronisation des données avec pull/push
   *
   * @async
   * @param {string} token - Token utilisé pour la synchronisation
   * @throws {Error} - Erreur JWT
   * @throws {Error} - Erreur contrainte
   * @throws {Error} - Erreur failed to fetch
   * @throws {Error} - Autres erreurs
   * @return {Promise<RxGraphQLReplicationState<T>} - L'objet qui représente la synchronisation rxdb
   */
  setupGraphQLReplication() {
    // if (this.schemaLiteral.name === 'emplacement_tree') debugger;

    const replicateState = replicateRxCollection<T, Checkpoint>({
      collection: this.collection,
      replicationIdentifier:
        this.schemaLiteral.name + '-replication-to-' + environment.HASURA_URL,
      retryTime: 5000,
      autoStart: true,
      deletedField: 'deleted_bool',
      pull: {
        handler: async (last, size) => await this.getPullQuery(last, size),
        batchSize: this.BATCH_SIZE,
        modifier: this.pullQueryModifier,
      },
      ...(this.enablePush && {
        push: {
          handler: async (docs) => await this.getPushQuery(docs),
          batchSize: 1,
        },
      }),
      live: true,
    });

    try {
      this.unsubscribeReplicateState();

      this.replicateStateSubscriptions.push(
        replicateState.error$.subscribe((error: RxError | RxTypeError) => {
          this.manageReplicateStateError(error);
        })
      );

      // Pour ce replicateState, on ecoute "received"
      this.replicateStateSubscriptions.push(
        replicateState.received$
          .pipe(debounceTime(WAIT_DURATION.MEDIUM))
          .subscribe((e) => {
            this.pullQueryUpdated$.next(null);
          })
      );

      return replicateState;
    } catch (error) {
      console.log(error);
    }
  }

  private unsubscribeReplicateState() {
    this.replicateStateSubscriptions.forEach((rs: Subscription) => {
      rs.unsubscribe();
    });
  }

  manageReplicateStateError(error: RxError | RxTypeError) {
    console.log('manageReplicateStateError', error, {
      provider: this.schemaLiteral.name,
      code: error.code,
      parameters: error.parameters,
    });

    if (error.parameters.errors?.[0]) {
      const originalError = error.parameters.errors[0];
      const errorCode = originalError.extensions?.code;
      const rxdbErrorCode = error.code;
      // Handle specific error codes
      if (errorCode === 426) {
        SentryService.captureException(originalError, {
          provider: this.schemaLiteral.name,
          code: errorCode,
          rxdbCode: rxdbErrorCode,
          reason: 'Replication : Client outdated',
        });
        // Client is outdated -> enforce a page reload
        location.reload();
      } else if (errorCode === 401) {
        // Unauthorized -> maybe the token expired, try refreshing
        auth.refreshSession().catch((err) => {
          SentryService.captureException(err, {
            provider: this.schemaLiteral.name,
            code: errorCode,
            rxdbCode: rxdbErrorCode,
            reason: 'Replication : Failed to refresh session',
          });
          this.AT.toastError($localize`Failed to refresh session`);
        });
      } else {
        SentryService.captureException(originalError, {
          provider: this.schemaLiteral.name,
          code: errorCode,
          rxdbCode: rxdbErrorCode,
          reason: 'Replication : Unhandled error',
        });
        // Handle other errors
        this.AT.toastError(
          $localize`Replication error: ${originalError.message}`
        );
      }
    }
  }

  /**
   * Push Query pour la synchronisation
   * A IMPLEMENTER DANS LES CLASSES QUI HERITENT
   *
   * @throws {Error} - Erreur si non surchargée dans les classes filles
   * @return {RxGraphQLReplicationPushQueryBuilder} - La push query pour la synchronisation RXDB
   */
  getPushQuery(
    docs: RxReplicationWriteToMasterRow<T>[]
  ): Promise<WithDeleted<T>[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Pull Query pour la synchronisation
   * A IMPLEMENTER DANS LES CLASSES QUI HERITENT
   *
   * @throws {Error} - Erreur si non surchargée dans les classes filles
   * @return {RxGraphQLReplicationPullQueryBuilder<Checkpoint>} - La pull query pour la synchronisation RXDB
   */
  getPullQuery(
    lastCheckpoint: Checkpoint | undefined,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<T, Checkpoint>> {
    throw new Error('Method not implemented.');
  }

  /**
   * Hook qui permet la modification des données reçus suite à la pull query
   * Cette fonction est appelée pour chaque élément avant enregistrement dans RXDB
   *
   * @param {T} doc - Objet (JSON) correspondant à un élément récupérée par la pull query
   * @return {T} - Objet modifié pour être enregistrer dans RXDB
   */
  pullQueryModifier(doc: any): MaybePromise<WithDeleted<T>> {
    return doc;
  }

  /**
   * Permet de lancer / relancer la synchronisation des données
   *
   * @async
   * @return {Promise<void>}
   */
  async resetSync() {
    if (!this.auth.getIsOnline()) {
      console.log(
        this.schemaLiteral.name + ' : [Offline] Pas de synchronisation'
      );
      return;
    }
    console.log(this.schemaLiteral.name + ' : [Online] Synchronisation');
    this.resetSyncRunning = true;

    if (!this.replicateState) {
      const result = this.setupGraphQLReplication();
      if (!result)
        throw new Error(
          'Erreur setupGraphQLReplication ' + this.schemaLiteral.name
        );

      this.replicateState = result;
      await this.replicateState.awaitInitialReplication().catch((e) => {
        console.error('Initial replication failed:', e);
        throw new Error(
          'Initial replication failure for ' + this.schemaLiteral.name
        );
      });
    }

    if (this.replicateState.pull && this.replicateState.isStopped()) {
      console.log('replicateState reSync ' + this.schemaLiteral.name);
      this.replicateState.reSync();
    }

    this.resetSyncRunning = false;
  }

  /**
   * Permet de stopper la synchronisation RXDB
   *
   * @async
   * @return {Promise<void>} - Ne retourne rien
   */
  async stopSync(): Promise<void> {
    if (this.replicateState?.cancel) {
      await this.replicateState.cancel();
    }
  }

  /**
   * Permet la mise/remise en place de la collection et de la synchronisation
   *
   * @async
   * @param {AuthenticationService} auth - Service d'authentification pour le token, idUser, isSuperAdmin
   * @return {Promise<void>} - Ne retourne rien
   */
  async reset() {
    this.isSuperAdmin =
      this.auth.defaultRole === UGAU_USER_ROLE.SUPER_ADMIN.toString();
    this.idUser = this.auth.getUserId();
    const token = this.auth.getToken();

    await this.resetCollection();
    // Pas de synchronisation si pas de token
    if (token) {
      await this.resetSync();
    }

    await this.doThingAfterInitialReplication();
    console.log('reset ' + this.schemaLiteral.name + ' done');
  }

  /**
   * Permet de vérifier si une collection est vide
   *
   * @async
   * @return {Promise<boolean>} - True si vide, false si non vide
   */
  public async isEmptyCollection(): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.collection) {
      return true;
    }
    const doc_count = await this.collection.count().exec();
    return doc_count === 0;
  }

  /**
   * Permet de surcharger du code custom à éxécuter après le awaitInitialReplication
   *
   * @async
   * @return {Promise<void>} Ne retourne rien
   */
  async doThingAfterInitialReplication(): Promise<void> {}

  async stop(): Promise<void> {
    await this.stopSync();
  }

  async deleteDb(): Promise<void> {
    const dbName = this.getDbName();
    if (!dbName) {
      // Nom de la base de données introuvable
      return;
    }

    const exist = await Dexie.exists(dbName);
    if (!exist) {
      // La base de donnée IDB n'existe pas
      return;
    }

    await Dexie.delete(dbName);
  }

  protected getDbName(): string {
    return this.collection.database.name;
  }

  getEntityName() {
    return this.schemaLiteral.name;
  }

  removeReplication() {
    this.unsubscribeReplicateState();
    this.replicateState = null;
  }

  getOrForQuery(
    lastCheckpoint: Checkpoint,
    propId = 'id',
    propUpdatedAt = 'updated_at'
  ) {
    const minTimestamp = lastCheckpoint
      ? lastCheckpoint.updatedAt
      : new Date(Date.UTC(0, 0, 0, 0, 0, 0)).toISOString();
    const _or: any[] = [
      {
        [propUpdatedAt]: { _gt: minTimestamp },
      },
    ];
    if (lastCheckpoint?.id) {
      _or.push({
        [propUpdatedAt]: { _gte: minTimestamp },
        [propId]: { _neq: lastCheckpoint.id },
      });
    }
    return _or;
  }

  getOrForQueryCamelCase(lastCheckpoint: Checkpoint) {
    const minTimestamp = lastCheckpoint
      ? lastCheckpoint.updatedAt
      : new Date(Date.UTC(0, 0, 0, 0, 0, 0)).toISOString();
    const _or: any[] = [
      {
        updatedAt: { _gt: minTimestamp },
      },
    ];
    if (lastCheckpoint?.id) {
      _or.push({
        updatedAt: { _gte: minTimestamp },
        id: { _neq: lastCheckpoint.id },
      });
    }
    return _or;
  }

  getOrderByForQuery(propId = 'id', propUpdatedAt = 'updated_at') {
    return [
      {
        [propUpdatedAt]: 'asc',
      },
      {
        [propId]: 'asc',
      },
    ];
  }

  getOrderByForQueryCamelCasse() {
    return [
      {
        updatedAt: 'asc',
      },
      {
        id: 'asc',
      },
    ];
  }

  getAllValue() {
    return this.getAllSubject$.getValue();
  }

  fetchLot(startIndex: number, batchSize: number) {
    return this.collection.find().skip(startIndex).limit(batchSize).exec();
  }
  fetchLot$(startIndex: number, batchSize: number) {
    return this.collection.find().skip(startIndex).limit(batchSize).$;
  }

  findById(id: string) {
    return this.collection.findOne().where('id').equals(id).exec();
  }
  findById$(id: string) {
    return this.collection.findOne().where('id').equals(id).$;
  }
}
