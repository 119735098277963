import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const producttreeSchemaLiteral = jsonSchema({
  name: 'product_tree',
  autoMigrate: true,
  version: 1,
  type: 'object',
  required: ['id', 'hash', 'json', 'updated_at', 'order'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    hash: {
      type: 'string',
    },
    json: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    order: {
      type: ['number', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
  },
} as const);

const producttreeSchemaTyped = toTypedRxJsonSchema(producttreeSchemaLiteral);

export type producttreeDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof producttreeSchemaTyped
>;

export const producttreeSchema: RxJsonSchema<producttreeDocType> =
  producttreeSchemaLiteral;
