import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  private visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
    map(() => document.visibilityState),
    startWith(document.visibilityState)
  );

  public visible$: Observable<boolean> = this.visibilityChange$.pipe(
    filter((state) => state === 'visible'),
    map(() => true)
  );

  public hidden$: Observable<boolean> = this.visibilityChange$.pipe(
    filter((state) => state === 'hidden'),
    map(() => false)
  );

  public getVisibilityState$(): Observable<'visible' | 'hidden'> {
    return this.visibilityChange$ as Observable<'visible' | 'hidden'>;
  }
}
