import { inject } from '@angular/core';
import { EmplacementProvider } from './providers/emplacement/emplacement-provider';
import { EmplacementMobileProvider } from './providers/emplacement/emplacement-provider.mobile';
import { FabricantProvider } from './providers/fabricant-provider';
import { GroupeProvider } from './providers/groupe-provider';
import { InterventionProvider } from './providers/intervention-provider';
import { ProductTreeProvider } from './providers/product-tree-provider';
import { ProductfieldsProvider } from './providers/productfields-provider';
import { ProductpropertiesProvider } from './providers/productproperties-provider';
import { ReglementationProvider } from './providers/reglementation-provider';
import { TagProvider } from './providers/tag-provider';
import { UsersProvider } from './providers/users-provider';

/**
 * Initialise les providers pour un environnement de bureau.
 * @returns {Providers} - Les providers initialisés.
 */
export function initMobileProviders() {
  return {
    user: inject(UsersProvider),
    groupe: inject(GroupeProvider),
    tag: inject(TagProvider),
    productproperties: inject(ProductpropertiesProvider),
    reglementation: inject(ReglementationProvider),
    productfields: inject(ProductfieldsProvider),
    fabricant: inject(FabricantProvider),
    producttree: inject(ProductTreeProvider),
    emplacement: inject(EmplacementMobileProvider),
    intervention: inject(InterventionProvider),
  };
}

/**
 * Initialise les providers pour un environnement mobile.
 * @returns {Providers} - Les providers initialisés pour mobile.
 */
export function initProviders() {
  return {
    user: inject(UsersProvider),
    groupe: inject(GroupeProvider),
    tag: inject(TagProvider),
    productproperties: inject(ProductpropertiesProvider),
    reglementation: inject(ReglementationProvider),
    productfields: inject(ProductfieldsProvider),
    fabricant: inject(FabricantProvider),
    producttree: inject(ProductTreeProvider),
    emplacement: inject(EmplacementProvider),
    intervention: inject(InterventionProvider),
  };
}
