import { Injectable } from '@angular/core';
import { sortBy } from 'lodash-es';
import { AbstractProvider } from './abstract-provider';
import { Checkpoint } from './Checkpoint.type';
import {
  producttreeDocType,
  producttreeSchema,
  producttreeSchemaLiteral,
} from '../schemas/producttree.schema';
import { GET_PRODUCT_TREE_CACHE } from '../../../common-projects/get-product-tree-cache.queries';
import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { gql } from 'graphql-request';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { graphql } from './../../services/nhost';

@Injectable({
  providedIn: 'root',
})
export class ProductTreeProvider extends AbstractProvider<producttreeDocType> {
  public schema = producttreeSchema;
  public schemaLiteral = producttreeSchemaLiteral;
  public enablePush = false;
  public BATCH_SIZE = 1;
  protected migrationStrategies = {
    1: function (oldDoc: producttreeDocType) {
      return null;
    },
  };

  static productTree: object[];

  async resetCollection(): Promise<void> {
    const rxCollection = await this.setupCollection();
    if (!rxCollection) return;

    this.collection = rxCollection;
    this.collection
      .find()
      .$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.getAllSubject$.next(
          sortBy(
            val.map((el) => el.toJSON(true) as producttreeDocType),
            'order'
          )
        );
      });
  }
  familiesId = [];

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<producttreeDocType, Checkpoint>> {
    // Il faut récupérer les familles associées au groupe de l'utilisateur
    // Pour chaque famille, récupérer les cache de product_tree associés

    const { error: errorGroupe, data: dataGroupe } = await graphql.request(
      QUERY_GROUPE_FAMILIES,
      {
        _eq: this.auth.getUserId(),
      }
    );
    if (errorGroupe) {
      console.log(`Erreur lors de la récupération des familles sur le serveur`);
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    // On récupère les id des familles
    this.familiesId = dataGroupe.groupe_productfamilies.map(
      (e: any) => e.productfamily_id
    );

    // On récupère les caches de product_tree associés aux familles
    const { data, error } = await graphql.request(GET_PRODUCT_TREE_CACHE, {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
        family_id: {
          _in: this.familiesId,
        },
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    });

    if (error || !data.product_tree_cache) {
      console.log(`Erreur lors de la récupération des modules sur le serveur`);
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.product_tree_cache;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  async doThingAfterInitialReplication(): Promise<void> {
    await this.cleanExpiredOrDeletedCacheFromServer();
  }

  private async cleanExpiredOrDeletedCacheFromServer() {
    const variables = {
      where: {
        family_id: {
          _in: this.familiesId,
        },
      },
    };
    const { data: dataResult, error } = await graphql.request(
      QUERY_PRODUCT_TREE_CACHE,
      variables
    );
    if (error) {
      console.log(`Erreur lors de la récupération des modules sur le serveur`);
      return;
    }

    const data = dataResult?.product_tree_cache.map((f: any) => f.id);

    if (data?.filter((e: any) => e).length) {
      const queryFound = this.collection.find({
        selector: {
          id: {
            $nin: data,
          },
        },
      });
      await queryFound.remove();
      this.resetCollection();
    }
  }
}

const QUERY_GROUPE_FAMILIES = gql`
  query getGroupeFamilies($_eq: uuid = "") {
    groupe_productfamilies(
      where: { groupe: { user_groupes: { id_user: { _eq: $_eq } } } }
    ) {
      productfamily_id
    }
  }
`;

const QUERY_PRODUCT_TREE_CACHE = `
  query MyQuery($where: product_tree_cache_bool_exp = {}) {
    product_tree_cache(where: $where) {
      id
    }
  }
`;
