import { gql } from 'graphql-request';

export const QUERY_GET_TAGS = gql`
  query MyQuery(
    $where: tag_bool_exp!
    $order_by: [tag_order_by!]!
    $limit: Int!
  ) {
    tag(where: $where, order_by: $order_by, limit: $limit) {
      id
      libelle
      is_archived
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
      deleted_bool
    }
  }
`;

export const MUTATION_INSERT_TAGS = gql`
  mutation MyMutation(
    $objects: [tag_insert_input!]!
    $on_conflict: tag_on_conflict!
  ) {
    insert_tag(objects: $objects, on_conflict: $on_conflict) {
      returning {
        id
      }
    }
  }
`;

export const QUERY_V2_GET_TAGS = gql`
  query MyQuery(
    $where: tag_bool_exp = {}
    $order_by: [tag_order_by!] = {}
    $limit: Int!
  ) {
    tag(where: $where, order_by: $order_by, limit: $limit) {
      id
      id_user
      id_groupe
      libelle
      is_archived
      id_parent
      type

      shared_tags {
        id
        active
        readonly
        expires_at
        shared_at
        source_user_id
        target_user_id
        source_group_id
        target_group_id
      }

      groupe_libelle

      user_libelle

      created_by
      created_at
      updated_by
      updated_at
      deleted_by
      deleted_at
      deleted_bool
    }
  }
`;
