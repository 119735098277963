import * as Sentry from '@sentry/angular-ivy';
import { ScopeContext } from '@sentry/types';
import { environment } from './../../environments/environment';

export module SentryService {
  export function throwError(
    error: any,
    extra?: any,
    options?: Partial<ScopeContext>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!environment.ENABLE_SENTRY) return console.error(error, extra, options);

    if (typeof error === 'string') {
      SentryService.captureMessage(error, extra, options);
      throw new Error(error);
    } else {
      SentryService.captureException(error, extra, options);
      throw error;
    }
  }
  export function captureException(
    error: any,
    extra?: any,
    options?: Partial<ScopeContext>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!environment.ENABLE_SENTRY) return console.error(error, extra, options);

    Sentry.captureException(error, {
      user: options?.user!,
      level: options?.level ?? 'error',
      extra,
      contexts: options?.contexts,
      tags: options?.tags,
      fingerprint: options?.fingerprint,
      requestSession: options?.requestSession,
    });
  }

  export function captureMessage(
    message: string,
    extra?: any,
    options?: Partial<ScopeContext>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!environment.ENABLE_SENTRY) return console.log(message, extra, options);

    Sentry.captureMessage(message, {
      user: options?.user!,
      level: options?.level ?? 'error',
      extra,
      contexts: options?.contexts,
      tags: options?.tags,
      fingerprint: options?.fingerprint,
      requestSession: options?.requestSession,
    });

    // on envoie aussi l'erreur vers la console
    if (options?.level === 'error' || options?.level === 'fatal')
      console.error(message);
    if (options?.level === 'warning') console.warn(message);
    if (options?.level === 'info' || options?.level === 'log')
      console.log(message);

    if (options?.level === 'debug') console.debug(message);
  }

  export function flush() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!environment.ENABLE_SENTRY) return Promise.resolve();

    return Sentry.flush(2000);
  }
}
