import { Injectable } from '@angular/core';
import { AbstractProvider } from './abstract-provider';
import { Checkpoint } from './Checkpoint.type';
import { QUERY_GET_PRODUCTPROPERTIES } from '../queries/productproperties.queries';
import {
  productpropertiesDocType,
  productpropertiesSchema,
  productpropertiesSchemaLiteral,
} from '../schemas/productproperties.schema';
import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { graphql } from './../../services/nhost';

// REFACTO : Le fonctionnement de RXDB doit être abstrait de la classe
@Injectable({
  providedIn: 'root',
})
export class ProductpropertiesProvider extends AbstractProvider<productpropertiesDocType> {
  public schema = productpropertiesSchema;
  public schemaLiteral = productpropertiesSchemaLiteral;
  public enablePush = false;
  public BATCH_SIZE = 5000;

  public staticsCollectionFunctions = {};

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<
    ReplicationPullHandlerResult<productpropertiesDocType, Checkpoint>
  > {
    const { data, error } = await graphql.request(QUERY_GET_PRODUCTPROPERTIES, {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    });

    if (error || !data.productproperties) {
      console.log(
        `Erreur lors de la récupération des productproperties sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.productproperties;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }
}
