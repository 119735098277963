import { LOCALSTORAGE } from '../enum/local-storage';

export function getFromLocalStorage(
  key: string,
  doJsonParse = false
): null | string | any {
  const value = localStorage.getItem(key);
  if (value && !doJsonParse) {
    return value;
  }
  if (value && doJsonParse) {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.log('JSON parse error : ' + key, error);
      return null;
    }
  }
  return '';
}

export function setToLocalStorage(key: string | LOCALSTORAGE, value: any) {
  switch (typeof value) {
    case 'string':
    case 'boolean':
    case 'number':
      localStorage.setItem(key, value.toString());
      break;
    case 'object':
      localStorage.setItem(key, JSON.stringify(value));
      break;
  }
}

export function removeFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function setMaxAgeClasse(num: number, value: string | number) {
  if (typeof value == 'number') {
    value = value.toString();
  }
  setToLocalStorage('age_limit_' + num, value);
}

export function setComposantOpenedByDefault(bool: boolean) {
  setToLocalStorage(LOCALSTORAGE.DEFAULT_COMPOSANT_OPENED, bool);
}

export function getComposantOpenedByDefault(): 'true' | 'false' {
  const defaultValue = getFromLocalStorage(
    LOCALSTORAGE.DEFAULT_COMPOSANT_OPENED
  );
  // Par défaut, si vide on ouvre tous les composants
  if (defaultValue === '') {
    return 'true';
  }

  return defaultValue === 'true' ? 'true' : 'false';
}

export function getDefaultMapStyle() {
  return getFromLocalStorage(LOCALSTORAGE.MAPBOX_DEFAULT_STYLE, true);
}
