import { v4 as uuidv4 } from 'uuid';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { Router, NavigationEnd, DefaultUrlSerializer } from '@angular/router';
import { LngLat } from 'mapbox-gl';
import { BehaviorSubject, Observable, filter, map, shareReplay } from 'rxjs';
import { EMPLACEMENT_STATUS } from '../../common-projects/emplacement_status';
import { URL_PAGE_ACTION, URL_DISPLAY_ACTION } from '../enum/url-emplacement';
import { regexUuidString } from './regexUuidString';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../utils/localstorage-utils.service';
import {
  RoutingStateTypeInput,
  RoutingStateType,
} from './routing-state.interface';
import { MapboxImplantationModeState } from '../services/mapbox/mapbox-implantation-mode-state.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingState {
  private urlSerializer = new DefaultUrlSerializer();

  private routingSubject$: BehaviorSubject<RoutingStateTypeInput> =
    new BehaviorSubject<RoutingStateTypeInput>(this.loadInitialState());
  routing$: Observable<RoutingStateType> = this.routingSubject$
    .asObservable()
    .pipe(
      map((e) => {
        return {
          ...e,
          computed: {
            isCurrentActionDetail: e.page === URL_PAGE_ACTION.DETAIL,
            isCurrentActionCreation:
              e.page === URL_PAGE_ACTION.CREATE ||
              e.page === URL_PAGE_ACTION.CHOOSE_ADD,
            isEmplacementShown:
              e.page === URL_PAGE_ACTION.DETAIL ||
              e.page === URL_PAGE_ACTION.CREATE ||
              e.page === URL_PAGE_ACTION.CHOOSE_ADD,
            isEmplacementLocationSelectorShown:
              e.action === URL_DISPLAY_ACTION.LOCATION_SELECTOR,
            isEmplacementPhotoFullShown: e.action === URL_DISPLAY_ACTION.PHOTOS,
            isEmplacementPhotoSelectorShown:
              e.action === URL_DISPLAY_ACTION.PRISE_PHOTO ||
              e.action === URL_DISPLAY_ACTION.PRISE_PHOTO_DIRECT,
          },
        };
      }),
      shareReplay(1)
    );

  isCurrentActionDetail$: Observable<boolean> = this.routing$.pipe(
    map((e) => e.computed.isCurrentActionDetail)
  );
  isCurrentActionCreation$ = this.routing$.pipe(
    map((e) => e.computed.isCurrentActionCreation)
  );
  isEmplacementShown$ = this.routing$.pipe(
    map((e) => e.computed.isEmplacementShown)
  );
  isEmplacementLocationSelectorShown$ = this.routing$.pipe(
    map((e) => e.computed.isEmplacementLocationSelectorShown)
  );
  isEmplacementPhotoFullShown$ = this.routing$.pipe(
    map((e) => e.computed.isEmplacementPhotoFullShown)
  );
  isEmplacementPhotoSelectorShown$ = this.routing$.pipe(
    map((e) => e.computed.isEmplacementPhotoSelectorShown)
  );

  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private implantationModeState = inject(MapboxImplantationModeState);

  constructor() {
    this.listenAngularRouter();
  }

  // Always return EMPTY state
  private loadInitialState(): RoutingStateTypeInput {
    const state = getFromLocalStorage('routingState', true);
    if (state?.source_event) {
      // SPECIFIC CASE : Redo old navigation
      setTimeout(() => {
        console.log('Redo old navigation');
        this.router.navigateByUrl(state.source_event.url);
      }, 50);
    }
    return this.initialState();
  }

  private initialState() {
    return {
      page: undefined,
      action: undefined,
      id_emplacement: undefined,
      id_emplacement_photo: undefined,
      emplacement_status: undefined,
      latitude: undefined,
      longitude: undefined,
      forceOpenSearch: true,
      source_event: undefined,
    };
  }

  // Ex: /?panel=detail/6785DHJGFJ/localisation&filter[zone]=123&filter[classe]=2
  // Ex: /?panel=add/6785DHJGFJ/localisation&filter[zone]=123&filter[classe]=2
  // Ex: /?panel=detail/6785DHJGFJ/picture/XXXXXXXXXX&filter[zone]=123&filter[classe]=2
  listenAngularRouter() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event) => {
        const queryParams = this.urlSerializer.parse(event.url).queryParams;
        const queryString = queryParams.panel;

        if (!queryString) {
          this.saveState(this.initialState());
          return;
        }

        const { page, action, idEmplacement, idEmplacementPhoto } =
          this.extractRoutingInformation(queryString);

        const emplacementStatus = queryParams.status as
          | EMPLACEMENT_STATUS
          | undefined;

        const { lat, lng } = queryParams;
        const forceOpenSearch = queryParams.open === 'true' || false;

        const state = {
          page,
          action,
          id_emplacement: idEmplacement,
          id_emplacement_photo: idEmplacementPhoto,
          emplacement_status: emplacementStatus,
          latitude: lat,
          longitude: lng,
          forceOpenSearch,
          source_event: event,
        };

        this.saveState(state);
        this.routingSubject$.next(state);
      });
  }

  private extractRoutingInformation(queryString: string): {
    page: URL_PAGE_ACTION | undefined;
    action: URL_DISPLAY_ACTION | undefined;
    idEmplacement: string | undefined;
    idEmplacementPhoto: string | undefined;
  } {
    const page = this.extractPage(queryString);
    const action = this.extractAction(queryString, page);
    const idEmplacement = this.extractIdEmplacement(queryString, page);
    const idEmplacementPhoto = this.extractIdEmplacementPhoto(
      queryString,
      page
    );
    return { page, action, idEmplacement, idEmplacementPhoto };
  }

  saveState(state: RoutingStateTypeInput) {
    setToLocalStorage('routingState', state);
  }

  private extractPage(queryString: string) {
    for (const page of Object.values(URL_PAGE_ACTION)) {
      const regex = new RegExp(`${page}`);
      const match = queryString.match(regex);
      if (match) {
        return page;
      }
    }

    return undefined;
  }

  private extractAction(
    queryString: string,
    page: URL_PAGE_ACTION | undefined
  ) {
    if (!page) return undefined;
    for (const display of Object.values(URL_DISPLAY_ACTION)) {
      const regex = new RegExp(`${page}${regexUuidString}/${display}`, 'i');
      const match = queryString.match(regex);
      if (match) {
        return display;
      }
    }

    const regex = new RegExp(`${page}${regexUuidString}$`, 'i');

    const match = queryString.match(regex);
    if (match) {
      return URL_DISPLAY_ACTION.DETAIL;
    }

    return undefined;
  }

  private extractIdEmplacement(
    queryString: string,
    page: URL_PAGE_ACTION | undefined
  ) {
    const regex = new RegExp(`${page}(${regexUuidString})`, 'i');
    const idEmplacement = regex.exec(queryString);
    if (idEmplacement?.[1]) {
      return idEmplacement[1];
    }
    return undefined;
  }

  private extractIdEmplacementPhoto(
    queryString: string,
    page: URL_PAGE_ACTION | undefined
  ) {
    const regex = new RegExp(
      `${page}${regexUuidString}/${URL_DISPLAY_ACTION.PHOTOS}/(${regexUuidString})`,
      'i'
    );
    const idEmplacementPhoto = regex.exec(queryString);
    if (idEmplacementPhoto?.[1]) {
      return idEmplacementPhoto[1];
    }
    return undefined;
  }

  getRouting() {
    return this.routingSubject$.getValue();
  }

  isCreateCurrentAction() {
    return (
      this.getRouting().page === URL_PAGE_ACTION.CREATE ||
      this.getRouting().page === URL_PAGE_ACTION.CHOOSE_ADD
    );
  }

  setActionPage(page: URL_PAGE_ACTION | undefined) {
    if (page)
      this.routingSubject$.next({
        ...this.routingSubject$.getValue(),
        page: page,
      });
  }

  setActionDisplay(action: URL_DISPLAY_ACTION | undefined) {
    if (action)
      this.routingSubject$.next({
        ...this.routingSubject$.getValue(),
        action: action,
      });
  }

  navigateEmplacementPhoto(idEmplacement: string, idEmplacementPhoto: string) {
    const page = this.getRouting().page;
    return this.router.navigate(['/'], {
      queryParams: {
        panel: `${page}${idEmplacement}/${URL_DISPLAY_ACTION.PHOTOS}/${idEmplacementPhoto}`,
      },
    });
  }
  navigateEmplacementPhotoCreate(idEmplacement: string) {
    const state = this.getRouting();
    this.clearEmplacementPhoto();

    return this.router.navigate(['/'], {
      queryParams: {
        panel: `${state.page}${idEmplacement}/${URL_DISPLAY_ACTION.PRISE_PHOTO}`,
      },
    });
  }
  navigateHome() {
    return this.router.navigate(['/'], {
      queryParams: {},
    });
  }
  navigateEmplacement(idEmplacement: string) {
    return this.router.navigate(['/'], {
      queryParams: {
        panel: `${URL_PAGE_ACTION.DETAIL}${idEmplacement}/${URL_DISPLAY_ACTION.DETAIL}`,
      },
    });
  }
  navigateEmplacementCreate(
    latlng: LngLat | undefined = undefined,
    uuid: string = uuidv4()
  ) {
    const panel = `${URL_PAGE_ACTION.CREATE}${uuid}/${URL_DISPLAY_ACTION.LOCATION_SELECTOR}`;
    const implatationState = this.implantationModeState.getImplantationMode();
    if (implatationState.mode === 'implantation') {
      status = EMPLACEMENT_STATUS.NON_POSE;
    }
    if (implatationState.mode === 'releve') {
      status = EMPLACEMENT_STATUS.POSE;
    }

    if (!latlng) {
      return this.router.navigate(['/'], {
        queryParams: {
          panel: panel,
          status,
        },
      });
    }

    return this.router.navigate(['/'], {
      queryParams: {
        panel: panel,
        lat: latlng.lat,
        lng: latlng.lng,
        status,
      },
    });
  }

  navigateCurrentEmplacement(additionnalQueryParams: any = {}) {
    const state = this.getRouting();
    if (!state.id_emplacement) {
      // @TODO : Throw error
      return;
    }
    return this.router.navigate(['/'], {
      queryParams: {
        panel: `${state.page}${state.id_emplacement}/${URL_DISPLAY_ACTION.DETAIL}`,
        ...additionnalQueryParams,
      },
    });
  }
  navigateCurrentEmplacementPhotoCreate(direct: boolean = false) {
    const state = this.getRouting();
    if (!state.id_emplacement) {
      // @TODO : Throw error
      return;
    }
    this.clearEmplacementPhoto();
    if (!direct)
      return this.router.navigate(['/'], {
        queryParams: {
          panel: `${state.page}${state.id_emplacement}/${URL_DISPLAY_ACTION.PRISE_PHOTO}`,
        },
      });

    return this.router.navigate(['/'], {
      queryParams: {
        panel: `${state.page}${state.id_emplacement}/${URL_DISPLAY_ACTION.PRISE_PHOTO_DIRECT}`,
      },
    });
  }
  navigateEmplacementLocationSelector(idEmplacement: string) {
    const state = this.getRouting();
    return this.router.navigate(['/'], {
      queryParams: {
        panel: `${state.page}${idEmplacement}/${URL_DISPLAY_ACTION.LOCATION_SELECTOR}`,
      },
    });
  }

  clearEmplacementPhoto() {
    this.routingSubject$.next({
      ...this.routingSubject$.getValue(),
      id_emplacement_photo: undefined,
    });
  }
  clearEmplacement() {
    this.routingSubject$.next({
      ...this.routingSubject$.getValue(),
      id_emplacement: undefined,
      id_emplacement_photo: undefined,
      action: undefined,
      page: undefined,
    });
  }
}
