import { LOCALSTORAGE } from '../enum/local-storage';
import { removeFromLocalStorage } from '../utils/localstorage-utils.service';

export const clearLocalStorageForLogout = () => {
  const keysOrKeyStartToKeep: string[] = [
    LOCALSTORAGE.MAPBOX_DEFAULT_STYLE,
    LOCALSTORAGE.OVERRIDE_DURATIONS,
    // La clef 'age_limit_' est un peu particulière car elle est suivie d'un nombre
    'age_limit_',
    'ARCADE_HELP_FIRST_LOGIN',
  ];

  const keysToClear = Object.keys(localStorage).filter(
    (key) =>
      !keysOrKeyStartToKeep.some((keyToKeep) => key.startsWith(keyToKeep))
  );

  keysToClear.forEach((key) => {
    removeFromLocalStorage(key);
  });
};
