export enum URL_PAGE_ACTION {
  DETAIL = 'detail/',
  CREATE = 'add/',
  CHOOSE_ADD = 'chooseAdd/',
}
export enum URL_DISPLAY_ACTION {
  PHOTOS = 'picture',
  PRISE_PHOTO = 'add_picture',
  PRISE_PHOTO_DIRECT = 'direct_add_picture',
  LOCATION_SELECTOR = 'location',
  DETAIL = 'view',
}
