import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { RestoredListenerEvent } from '@capacitor/app';
import { RoutingState } from './routing-state.service';
import { EmplacementPhotoSelectorState } from '../components/emplacement/emplacement-photo-selector/emplacement-photo-selector-state.service';
import { ToolsService } from '../services/tools.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateAppRestoredService {
  private appStoredResultSubject$: BehaviorSubject<any> =
    new BehaviorSubject<RestoredListenerEvent | null>(null);
  appStoredResult$: Observable<RestoredListenerEvent | null> =
    this.appStoredResultSubject$.asObservable();

  setAppStoredResult(data: RestoredListenerEvent) {
    this.appStoredResultSubject$.next(data);
  }

  getAppStoredResult() {
    return this.appStoredResultSubject$.getValue();
  }

  hasAppStoredResult() {
    return !!this.appStoredResultSubject$.getValue();
  }

  constructor(
    private tools: ToolsService,
    private routingState: RoutingState,
    private emplacementPhotoSelectorState: EmplacementPhotoSelectorState
  ) {
    if (!this.tools.isMobile()) {
      return;
    }

    combineLatest([
      this.appStoredResult$,
      this.routingState.routing$,
    ]).subscribe(([appRestoredResult, routing]) => {
      if (
        appRestoredResult &&
        this.isRestoredFromCamera(appRestoredResult) &&
        this.isRestoredSuccess(appRestoredResult) &&
        this.isAddPictureRoute(routing)
      ) {
        console.log(
          'Restored from camera and add picture route',
          appRestoredResult,
          routing
        );
        this.emplacementPhotoSelectorState.setUploadData(
          appRestoredResult.data.base64String
        );
      }
    });
  }

  isRestoredFromCamera(appRestoredResult: RestoredListenerEvent): boolean {
    return appRestoredResult.pluginId === 'Camera';
  }

  isRestoredSuccess(appRestoredResult: RestoredListenerEvent): boolean {
    return appRestoredResult.success;
  }

  isAddPictureRoute(routing: any): boolean {
    return routing.computed.isEmplacementLocationSelectorShown;
  }
}

// Exemple :
//   {
//     "pluginId": "Camera",
//     "methodName": "getPhoto",
//     "success": true,
//     "data": {
//         "format": "jpeg",
//         "base64String": "(...)",
//         "exif": {(...)}
//     }
// }
