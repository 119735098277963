import { RxReplicationWriteToMasterRow } from 'rxdb';
import { interventionDocType } from '../schemas/intervention.schema';

export const interventionReplicationPush = (
  docs: RxReplicationWriteToMasterRow<interventionDocType>[]
) => {
  const query = `
    mutation insert_interventions($objects: [interventions_insert_input!]!, $on_conflict: interventions_on_conflict) {
      insert_interventions(objects: $objects, on_conflict: $on_conflict) {
        returning {
          id
        }
      }
    }
  `;

  const variables = {
    objects: docs.map((rxDoc) => {
      const { deleted_bool, emplacements, documents, ...dataDoc } =
        rxDoc.newDocumentState;
      return {
        ...dataDoc,
        ...(emplacements.length && {
          emplacements: {
            data: emplacements,
            on_conflict: {
              constraint: 'intervention_emplacement_pkey',
              update_columns: [
                'updated_at',
                'updated_by',
                'deleted_at',
                'deleted_by',
                'date_start_intervention',
                'date_end_intervention',
              ],
            },
          },
        }),
        ...(documents.length && {
          documents: {
            data: documents,
            on_conflict: {
              constraint: 'intervention_document_pkey',
              update_columns: [
                'updated_at',
                'updated_by',
                'deleted_at',
                'deleted_by',
              ],
            },
          },
        }),
      };
    }),
    on_conflict: {
      constraint: 'interventions_pkey',
      update_columns: [
        'updated_at',
        'updated_by',
        'deleted_at',
        'deleted_by',
        'name',
        'numero',
        'date_en_cours',
        'date_termine',
        'status',
        'description',
      ],
    },
  };

  return {
    query,
    variables,
  };
};
