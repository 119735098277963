import { Injectable } from '@angular/core';
import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { Checkpoint } from './Checkpoint.type';
import { QUERY_GET_GROUPES } from '../queries/groupe.queries';
import {
  groupeDocType,
  groupeSchema,
  groupeSchemaLiteral,
} from '../schemas/groupe.schema';
import { graphql } from './../../services/nhost';

// REFACTO : Le fonctionnement de RXDB doit être abstrait de la classe
@Injectable({
  providedIn: 'root',
})
export class GroupeProvider extends AbstractProvider<groupeDocType> {
  schema = groupeSchema;
  schemaLiteral = groupeSchemaLiteral;
  enablePush = false;

  protected migrationStrategies = {
    1: function (oldDoc: groupeDocType) {
      return null;
    },
    2: function (oldDoc: groupeDocType) {
      return null;
    },
  };

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<groupeDocType, Checkpoint>> {
    const variables: any = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    };

    if (!this.isSuperAdmin) {
      variables.where.user_groupes = {
        id_user: {
          _eq: this.idUser,
        },
      };
    }
    const { data, error } = await graphql.request(QUERY_GET_GROUPES, variables);
    if (error || !data.groupe) {
      console.log(`Erreur lors de la récupération des groupes sur le serveur`);
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.groupe;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }
}
