import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { IsStringPipe } from '../pipes/is-string.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-dialog-message',
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div
      mat-dialog-content
      style="white-space: pre-line"
      *ngIf="!data.useHtmlContent"
    >
      {{ data.message }}
    </div>
    <div
      mat-dialog-content
      *ngIf="data.useHtmlContent"
      [innerHTML]="data.message"
    ></div>
    <div
      mat-dialog-actions
      align="end"
      [class]="data.columnForButton ? 'd-flex-column' : ''"
    >
      <ng-container *ngIf="data.buttons | isString">
        <button mat-button (click)="defaultHandler()">
          {{ data.buttons }}
        </button>
      </ng-container>
      <ng-container *ngIf="!(data.buttons | isString)">
        <button
          *ngFor="let button of data.buttons; trackBy: trackByFn"
          mat-button
          (click)="handler(button)"
        >
          {{ button.text }}
        </button>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, NgIf, MatButtonModule, NgFor, IsStringPipe],
})
export class DialogMessageComponent {
  trackByFn = (index: number, item: any) => item.text;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private dialogRef: MatDialogRef<DialogMessageComponent>
  ) {}

  public defaultHandler() {
    this.dialogRef.close();
  }

  public handler(button: { text: string; handler: () => void }) {
    button.handler();
    this.dialogRef.close();
  }
}

interface DialogData {
  title: string;
  message: string;
  button: string;
  buttons: string | { text: string; handler: () => void }[];
  columnForButton?: boolean;
  useHtmlContent?: boolean;
}
