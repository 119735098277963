import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types/replication-protocol';
import { MUTATION_INSERT_TAGS } from '../../db/queries/tag.queries';
import { tagDocType } from '../../db/schemas/tag.schema';

export function transformTagForPushQuery(
  docs: RxReplicationWriteToMasterRow<tagDocType>[]
) {
  const datas = docs.reduce(
    (acc: any[], rxDoc: RxReplicationWriteToMasterRow<tagDocType>) => {
      const doc = rxDoc.newDocumentState;
      const { user_libelle, groupe_libelle, deleted_bool, ...rest } = doc;
      const tag = {
        ...rest,
        shared_tags: {
          data:
            doc.shared_tags?.map((el) => {
              return {
                ...el,
              };
            }) ?? [],
          on_conflict: {
            constraint: 'shared_tag_pkey',
            update_columns: [
              'active',
              'readonly',
              'expires_at',
              'target_group_id',
              'target_user_id',
            ],
          },
        },
      };

      // Le tag doit être lié à un groupe ou un utilisateur
      if (tag.id_groupe || tag.id_user) acc.push(tag);
      return acc;
    },
    []
  );

  const variables: any = {
    objects: datas,
    on_conflict: {
      constraint: 'tag_pkey',
      update_columns: [
        'libelle',
        'id_parent',
        'id_user',
        'id_groupe',
        'updated_by',
        'updated_at',
        'deleted_by',
        'deleted_at',
        'type',
        'is_archived',
      ],
    },
  };

  return {
    query: MUTATION_INSERT_TAGS,
    variables,
  };
}
