import { RxDatabase } from 'rxdb';
import { Providers } from './providers-interface';
import { Subscription, merge, tap, interval } from 'rxjs';
import { EventsService } from '../services/events.service';
import { VisibilityService } from '../services/visibility.service';
import { SentryService } from '../services/sentry.service';

const LIVE_INTERVAL_SYNC_30 = 1000 * 30; // Adjust this interval as needed
const LIVE_INTERVAL_SYNC_60 = 1000 * 60; // Adjust this interval as needed
let syncSubscription: Subscription[] = [];
const subcriptions: Subscription[] = [];

/**
 * Réinitialise les collections de la base de données.
 * @param {RxDatabase} dbInstance - Instance de la base de données.
 * @param {Providers} providers - Les providers de données.
 */
export async function resetCollections(
  dbInstance: RxDatabase,
  providers: Providers
) {
  let k: keyof Providers;
  const promises: Promise<void>[] = [];
  for (k in providers) {
    const provider = providers[k];
    provider.setDbInstance(dbInstance);
    promises.push(
      provider.resetCollection().catch((e) => {
        SentryService.captureMessage(
          `Error while resetting collection ${k}: ${e}`
        );
      })
    );
  }
  await Promise.all(promises);
}

/**
 * Configure la réplication des collections de la base de données.
 * @param {RxDatabase} dbInstance - Instance de la base de données.
 * @param {Providers} providers - Les providers de données.
 * @param {boolean} isMobile - Indique si l'application s'exécute sur un appareil mobile.
 * @param {EventsService} event - Service d'événements.
 * @param {VisibilityService} visibilityService - Service de gestion de la visibilité.
 */
export async function setupReplication(
  dbInstance: RxDatabase,
  providers: Providers,
  isMobile: boolean,
  event: EventsService,
  visibilityService: VisibilityService
) {
  await [
    'producttree',
    'user',
    'groupe',
    'tag',
    'productproperties',
    'reglementation',
    'productfields',
    'fabricant',
    'intervention',
  ].reduce(async (acc, k) => {
    await acc;
    const provider = providers[k as keyof Providers];
    provider.setDbInstance(dbInstance);

    try {
      await provider.reset();
    } catch (e) {
      SentryService.captureException(e, `Error resetting provider ${k}: ${e}`);
    }
  }, Promise.resolve());

  providers.emplacement
    .reset()
    .then(() => {
      // Mise en place de la réplication des emplacement uniquement
      if (isMobile) {
        console.log('Mobile, no replication based interval/events');
        return;
      }

      subcriptions.forEach((sub) => sub.unsubscribe());

      const sub1 = event.subscribe('resync', () => {
        try {
          providers.emplacement.replicateState?.reSync();
          providers.tag.replicateState?.reSync();
          providers.intervention.replicateState?.reSync();
        } catch (e) {
          SentryService.captureException(e, `Error during resync event: ${e}`);
        }
      });

      const sub2 = merge(
        visibilityService.visible$.pipe(
          tap(() => {
            try {
              startIntervals(providers, syncSubscription);
            } catch (e) {
              SentryService.captureException(
                e,
                `Error during startIntervals: ${e}`
              );
            }
          })
        ),
        visibilityService.hidden$.pipe(
          tap(() => {
            try {
              stopIntervals();
            } catch (e) {
              SentryService.captureException(
                e,
                `Error during stopIntervals: ${e}`
              );
            }
          })
        )
      ).subscribe();

      subcriptions.push(sub1, sub2);
    })
    .catch((e) => {
      SentryService.captureException(
        e,
        `Error resetting provider emplacement: ${e}`
      );
    });
}

/**
 * Démarre les intervalles de synchronisation.
 * @param {Providers} providers - Les providers de données.
 */
function startIntervals(
  providers: Providers,
  syncSubscription: Subscription[]
): void {
  // console.log('startIntervals called');
  stopIntervals();

  // Do once at start
  providers.emplacement.replicateState?.reSync();
  providers.tag.replicateState?.reSync();
  providers.intervention.replicateState?.reSync();
  providers.user.replicateState?.reSync();
  providers.groupe.replicateState?.reSync();
  // console.log('Initial sync done');

  // Do every 30s
  const sub1 = interval(LIVE_INTERVAL_SYNC_30).subscribe(() => {
    providers.emplacement.replicateState?.reSync();
    providers.tag.replicateState?.reSync();
    providers.intervention.replicateState?.reSync();
    // console.log('30s sync done');
  });

  // Do every 60s
  const sub2 = interval(LIVE_INTERVAL_SYNC_60).subscribe(() => {
    providers.user.replicateState?.reSync();
    providers.groupe.replicateState?.reSync();
    // console.log('60s sync done');
  });

  syncSubscription.push(...[sub1, sub2]);
}

/**
 * Arrête les intervalles de synchronisation.
 */
function stopIntervals(): void {
  // console.log('stopIntervals called');
  syncSubscription.forEach((sub) => sub.unsubscribe());
  syncSubscription = [];
}
